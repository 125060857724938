<!--  -->
<template>
  <div class="salary-list-page">
    <!-- <TopWrapper :topObj="topObj" /> -->
    <div class="main-wrapper">
      <div class="top-react">
        <div class="salary-wrapper">
          <div class="left-title">
            <van-icon name="balance-list-o" :size="20" />&nbsp;工资单数据
          </div>
          <van-dropdown-menu class="rightmenu">
            <van-dropdown-item
              @change="changeYear"
              v-model="value1"
              :title="value1.toString()"
              :options="option1"
          /></van-dropdown-menu>
        </div>
      </div>
      <div class="main-list">
        <div class="list-box" v-if="list.length > 0">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="getList(userInfo)"
              class="list-box"
            >
              <van-cell
                v-for="(item, i) in list"
                :key="i"
                class="item"
                @click="handleApply(item)"
              >
                <!-- <div class="salary-panel-box"> -->
                <div class="salary-top-box">
                  <div class="left-name">{{ item.salaryMonth }}工资单</div>
                  <div class="right-detail">
                    明细&nbsp;<van-icon name="arrow" size="20%" />
                  </div>
                </div>
                <div class="salary-bottom-box">
                  <p class="amount">￥{{ item.thisSalary }}</p>

                  <p class="remark">实发工资</p>
                </div>
                <!-- </div> -->
              </van-cell>
            </van-list></van-pull-refresh
          >
        </div>
        <van-empty style="background: #fff" v-else description="暂无数据" />
      </div>
    </div>

    <van-dialog
      v-model="dialogPayroll"
      :show-cancel-button="true"
      title="输入密码"
      :beforeClose="handleConfirm"
    >
      <van-field
        v-model="password"
        placeholder="请输入密码"
        clearable
        autosize
        maxlength="20"
        type="password"
      />
    </van-dialog>
  </div>
</template>

<script>
import { getMyStatffInfo } from "@/api/setting/index.js";
import {
  queryMySalary,
  salaryPwdValid,
  pwdExpired,
} from "@/api/salary/index.js";
import TopWrapper from "@/components/topWrapper/index.vue";
import { Toast } from "vant";
import { querySalaryInfo } from "@/api/salary/index.js";

export default {
  data() {
    return {
      topObj: {
        bgColor: "#fff",
        title: "工资单",
        txtColor: "#000",
        isBack: true,
        isMsg: false,
      },
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
      listQuery: {
        pageNum: 1,
        pageSize: 3,
        salaryMonth: "",
        staffCode: "",
        year: "",
      },
      password: "",
      userInfo: null,
      value1: "请选择",
      curMonthData: { thisSalary: 0, salaryMonth: "" }, //当前月份工资情况
      listTotal: 0,
      dialogPayroll: false,
      dialogPayrollDetail: false,
      detailPassword: "",
      indexItem: null,
      preTime: "请选择",
    };
  },
  filters: {
    filterSalay(val) {
      return (val / 100).toFixed(2);
    },
  },
  components: { TopWrapper },
  watch: {},

  computed: {
    option1() {
      // let yearList = [{ text: "请选择", value: 0 }];
      let yearList = [];
      let smallYears = 2000;
      //获取当前时间
      let date = new Date();
      let nowYears = date.getFullYear();
      var Years = nowYears - smallYears;
      let arrYear = [];

      for (let i = 0; i <= Years; i++) {
        arrYear.push(nowYears--);
      }
      arrYear.forEach((ele) => {
        yearList.push({ text: ele, value: ele });
      });
      return yearList;
    },
  },

  mounted() {
    this.preTime = new Date().getFullYear();
    this.value1 = this.preTime;
    this.listQuery.year = this.value1;
    this.getUserInfo();
  },

  methods: {
    getpwdExpired() {
      pwdExpired().then((res) => {
        if (res) {
          let { data, success } = res;
          if (success) {
            this.dialogPayroll = !data;
            if (data) {
              this.getList();
            }
          }
        }
      });
    },
    handleApply(item) {
      this.$router.push({
        path: "/salaryDetail",
        query: {
          id: item.salaryMonth,
        },
      });
    },

    onRefresh() {
      if (this.list.length < this.listTotal) {
        this.finished = false; //清空类表数据
        this.loading = true; //加载状态
        this.getList(this.userInfo);
      } else {
        this.loading = false;
        this.refreshing = false;
      }
    },
    getUserInfo() {
      getMyStatffInfo().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.userInfo = data;
            this.list = [];
            this.getpwdExpired();
          }
        }
      });
    },
    getList(data) {
      this.loading = true;
      const params = {
        ...this.listQuery,
        staffCode: this.userInfo.staffCode,
        password: this.password,
      };
      queryMySalary(params)
        .then((res) => {
          this.loading = false;
          this.refreshing = false;
          let { data, code } = res;
          this.list = this.list.concat(data.records);
          this.listTotal = data.total;
          if (1 == this.listQuery.pageNum) {
            this.preTime = this.value1;
          }
          if (this.list.length >= data.total) {
            //如果没有数据了，把finished设置为true，之后就不会触发加载更多
            this.finished = true;
          } else {
            if (this.listQuery.pageNum >= data.pages) {
              //如果没有数据了，把finished设置为true，之后就不会触发加载更多
              this.finished = true;
            } else {
              this.listQuery.pageNum++;
            }
          }
        })
        .catch((res) => {
          if (1 == this.listQuery.pageNum) {
            this.value1 = this.preTime;
          }
        });
    },
    changeYear(val) {
      this.value1 = val;
      this.preTime = val;
      this.listQuery.year = val;
      this.listQuery.pageNum=1;
      this.list = [];
      this.getpwdExpired();
    },
    handleConfirm(action, done) {
      if (action != "confirm") {
        this.value1 = this.preTime;
        done();
        return;
      }
      if (!this.password) {
        Toast("密码不能为空");
        this.value1 = this.preTime;
        done(false);
        return;
      }
      this.list = [];
      this.listQuery = {
        pageNum: 1,
        pageSize: 3,
        year: this.value1,
        staffCode: "",
        salaryMonth: "",
      };

      this.refreshing = false;
      this.finished = false; //清空类表数据
      this.getList();
      done();
    },
  },
};
</script>
<style lang="scss" scoped>
.salary-list-page {
  width: 100%;
  height: auto;
  min-height: 100%;
  background: #fafafa;
  // position: relative;
  .main-wrapper {
    width: 100%;
    position: relative;
    .top-react {
      width: 100%;
      height: 3rem;
      background: #0094ee;

      padding: 0.3rem;

      .salary-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .left-title {
          font-size: 0.24rem;
          color: #fff;
          font-weight: 800;
          line-height: 0.4rem;
        }
        .rightmenu {
          width: 1.7rem;
          height: 0.4rem;
          position: absolute;
          z-index: 1000;
          right: 0.3rem;
          /deep/ .van-dropdown-menu__bar {
            background: transparent;
            line-height: 0.4rem;
            height: auto;
            border: 0.01rem solid #fff;
            border-radius: 0.2rem;
            .van-dropdown-menu__title {
              color: #fff !important;
            }
          }
        }
      }
      .salary-count {
        width: 100%;
        color: #fff;
        padding: 0.4rem 0.3rem;

        .amount {
          text-align: center;
          margin-bottom: 0.2rem;
          font-size: 0.6rem;
          font-weight: 700;
        }
        .date {
          text-align: center;
          margin-top: 0.1rem;
          font-size: 0.2rem;
        }
        .remark {
          text-align: center;
          margin-top: 0.1rem;
        }
      }
    }
    .main-list {
      padding: 0.3rem;
      position: absolute;
      top: 1.5rem;
      width: 100%;
      z-index: 100;
      background: #fafafa;
      .list-box {
        background: transparent;
        .item {
          width: 100%;
          margin-bottom: 0.3rem;
          border-radius: 0.2rem;
          background: #fff;
          border-radius: 0.16rem;
          height: 3.5rem;
          // .salary-panel-box {
          .salary-top-box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 0.005rem solid #ccc;
            padding: 0.2rem 0.3rem;
            .left-name {
              font-size: 0.3rem;
              line-height: 0.4rem;
              font-weight: 600;
            }
            .right-detail {
              font-size: 0.2rem;
              line-height: 0.4rem;
              color: #999999;
            }
          }
          .salary-bottom-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 3rem;
            .amount {
              text-align: center;
              margin-bottom: 0.2rem;
              font-size: 0.6rem;
              font-weight: 700;
              color: #0094ee;
            }

            .remark {
              text-align: center;
              margin-top: 0.1rem;
              color: #999999;
            }
          }
          // }
        }
        .item:last-child {
          margin-bottom: 0rem;
        }
        /deep/ .van-cell {
          padding: 0rem;
        }
      }
    }
  }
}
</style>
